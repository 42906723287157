import React, { useContext, useState, useEffect } from 'react'
import useSiteMetadata from '../../../helper/SiteMetadata'
import lang from '../../../helper/lang.json'

import lightLogo from "../../../../static/img/logo.png";
import darkLogo from "../../../../static/img/logo_dark.png";
import { LangContext } from '../../../context/langContext'
import { FaPaperPlane, FaMoon, FaSun } from "react-icons/fa";
const AmpNavbar = ({ selectedLang }) => {
  const { title, description, siteUrl } = useSiteMetadata()
  const { state, dispatch, state2, dispatch2 } = useContext(LangContext);

  const isInvert = state2.isNight;
  const inverClass = isInvert ? "navbar" : "navbar navbar_invert"
  const [showForm, setShowForm] = useState('none')
  const logo = isInvert ? lightLogo : darkLogo
  useEffect(() => {
    setShowForm('block')
  }, [])
  return (
    <div>
      <div class={` hamburger_wrapper hamburger_wrapper_background`}>
        <div
          id="hamburger"
          tabindex="0"
          role="button"
          aria-label="Menu button"
          on="tap:hamburger.toggleClass(class='close'),nav-menu.toggleClass(class='now-active')"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="nav-menu" className={` ${inverClass}`}>
        <ul class="list-reset m0 p0 ampstart-label">
          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <a
              href={`${siteUrl}${lang[selectedLang].slug}`}
              style={{ textDecoration: 'none', display: "inline" }}
              className="navbar-item"
            >
              <img src={logo} alt="Kaldi" />
            </a>
          </li>
          <li
            style={{ listStyle: 'none', marginBottom: '10px' }}
            class={`${selectedLang === 'hu' ? '' : 'hidden'}`}
          >
            <a
              href={`${siteUrl}/amp/en`}
              style={{ textDecoration: 'none' }}
            >
              en
            </a>
          </li>
          <li
            style={{ listStyle: 'none', marginBottom: '10px' }}
            class={`${selectedLang === 'hu' ? 'hidden' : ''}`}
          >
            <a
              href={`${siteUrl}/amp/`}
              style={{ textDecoration: 'none' }}
            >
              hu
            </a>
          </li>

          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <p
              style={{ cursor: "pointer", display: isInvert ? "inline" : "none" }}
              className="navbar-item"
              onClick={() => dispatch2({ type: "day" })}
            >
              <FaSun color={"lightgreen"} />
            </p>
            <p
              style={{ cursor: "pointer", display: isInvert ? "none" : "inline" }}
              className="navbar-item"
              onClick={() => dispatch2({ type: "night" })}
            >
              <FaMoon color={"lightgreen"} />
            </p>
          </li>


          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <a
              href={`${siteUrl}/amp${lang[selectedLang].slug}`}
              style={{ textDecoration: 'none' }}
            >
              {lang[selectedLang].home}
            </a>
          </li>
          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <a
              href={`${siteUrl}/amp${lang[selectedLang].slug}about`}
              style={{ textDecoration: 'none' }}
            >
              {lang[selectedLang].about}
            </a>
          </li>
          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <a
              href={`${siteUrl}/amp${lang[selectedLang].slug}service`}
              style={{ textDecoration: 'none' }}
            >
              {lang[selectedLang].services}
            </a>
          </li>
          <li style={{ listStyle: 'none', marginBottom: '10px' }}>
            <a
              href={`${siteUrl}/amp${lang[selectedLang].slug}products`}
              style={{ textDecoration: 'none' }}
            >
              {lang[selectedLang].products}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AmpNavbar
