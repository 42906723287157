import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/AmpLayout'
import Helmet from 'react-helmet'
import Content, { HTMLContent } from '../helper/Content'

const ServicePostPage = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark
  const { image } = frontmatter
  const PageContent = HTMLContent || Content
  const height = 10 / image.childImageSharp.fluid.aspectRatio
  return (
    <Layout selectedLang={pageContext.lang}>
      <Helmet>
        <title>{'Dekko|' + frontmatter.title}</title>
      </Helmet>
      <div className="fixedblock">
        <amp-img
          alt="Mountains"
          width="10"
          height={`${height}`}
          layout="responsive"
          srcSet={
            !!image.childImageSharp ? image.childImageSharp.fluid.srcSet : image
          }
          src={
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          }
        ></amp-img>
        <h1>{frontmatter.title}</h1>
        <PageContent className="content" content={html} />
      </div>
    </Layout>
  )
}

ServicePostPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      field: PropTypes.object,
      html: PropTypes.object,
      contentComponent: PropTypes.func,
    }),
  }),
}

export default ServicePostPage

export const pageQuery = graphql`
  query ServicePostPageAmp($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 600, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
            }
          }
        }
      }
    }
  }
`
