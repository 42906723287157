import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '../helper/SiteMetadata'
import LangProvider from '../context/langContext'
import lang from '../helper/lang.json'
import Navbar from './amp/Navbar'
import './page.css'
const TemplateWrapper = ({ children, selectedLang }) => {
  const { title, description, siteUrl } = useSiteMetadata()

  return (
    <LangProvider>
      <Helmet>
        <html lang={selectedLang} />
        <meta charset="UTF-8" />
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:description" content="/" />
        <meta property="og:image" content="/img/logo_dark.png" />
        <script async src="https://cdn.ampproject.org/v0.js"></script>
        <script
          async
          custom-element="amp-form"
          src="https://cdn.ampproject.org/v0/amp-form-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-sidebar"
          src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-install-serviceworker"
          src="https://cdn.ampproject.org/v0/amp-install-serviceworker-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-loader"
          src="https://cdn.ampproject.org/v0/amp-install-serviceworker-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-consent"
          src="https://cdn.ampproject.org/v0/amp-consent-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-analytics"
          src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-story"
          src="https://cdn.ampproject.org/v0/amp-story-1.0.js"
        ></script>
      </Helmet>
      <Navbar selectedLang={selectedLang} />


      <div>{children}</div>
      <amp-install-serviceworker
        src={`${siteUrl}/sw.js`}
        data-iframe-src={`${siteUrl}/sw.html`}
        layout="nodisplay"
      ></amp-install-serviceworker>
    </LangProvider>
  )
}

export default TemplateWrapper
